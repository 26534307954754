import React from 'react'
import {
    Box
} from '@chakra-ui/react'
const Wrapper = (props) => {
    return (
        <Box
            mx={{ base: 0, md: 'auto' }}
            px={{ base: 0, lg: 12 }}
            maxW={{ base: '100vw', lg: '1400px' }}
            minW={{ base: '100vw', lg: '1270px' }}
            {...props}
        >
            {props.children}
        </Box>
    )
}

export default Wrapper